import { useContext, useRef, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

import "./SearchPost.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";

function SearchPost() {
  const inputRef = useRef(null);

  const { dispatch, state } = useContext(Context);
  const { searchPostTab, currentTabName, showSearchPostPanel } = state;
  const [debouncedSearchPostTab, setDebouncedSearchPostTab] =
    useState(searchPostTab);

  useEffect(() => {
    const timer = setTimeout(
      () =>
        dispatch({
          type: "SET_SEARCH_POST_TAB",
          payload: debouncedSearchPostTab,
        }),
      1000
    );
    return () => clearTimeout(timer);
  }, [debouncedSearchPostTab]);

  useEffect(() => {
    const handleFilter = async () => {
      const tkn = localStorage.getItem("tkn");

      const config = {
        headers: {
          authorization: `${tkn}`,
        },
      };

      if (searchPostTab?.length >= 3) {
        dispatch({ type: "CLEAR_POST_TAB_RESULT" });
        dispatch({ type: "START_SEARCH_POST" });
        try {
          const { data } = await axios.get(
            `/searchtab?t=${currentTabName}&q=${debouncedSearchPostTab}`,
            config
          );

          dispatch({ type: "STOP_SEARCH_POST" });
          dispatch({ type: "SEARCH_POST_TAB_RESULT", payload: data.res });
        } catch (error) {
          console.error("Error fetching search results:", error);
          dispatch({ type: "STOP_SEARCH_POST" });
        }
      } else {
        dispatch({ type: "SET_SEARCH_POST_TAB", payload: "" });
      }
    };
    handleFilter();
  }, [searchPostTab]);

  useEffect(() => {
    if (inputRef.current && showSearchPostPanel) {
      inputRef.current.focus();
    }
  }, [showSearchPostPanel]);

  const clearPostTab = () => {
    inputRef.current.value = "";
    dispatch({ type: "CLEAR_SEARCH_POST_TAB", payload: "" });
    dispatch({ type: "CLEAR_POST_TAB_RESULT" });
    setDebouncedSearchPostTab("");
  };

  const onBlur = () => {
    dispatch({ type: "HIDE_SEARCH_POST_PANEL" });
    inputRef.current.value = "";
    dispatch({ type: "CLEAR_SEARCH_POST_TAB", payload: "" });
    dispatch({ type: "CLEAR_POST_TAB_RESULT" });
    setDebouncedSearchPostTab("");
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <div
        className={
          showSearchPostPanel ? "sTabInputContainerEx" : "sTabInputContainer"
        }
      >
        {searchPostTab?.length !== 0 && showSearchPostPanel ? (
          <CloseIcon className="sTabIcon" onClick={clearPostTab} />
        ) : (
          <img
            src="/assets/images/searchSmall.svg"
            alt=""
            className="sTabIcon"
          />
        )}
        <input
          type="text"
          placeholder="Search Posts"
          ref={inputRef}
          onChange={(e) => {
            setDebouncedSearchPostTab(e.target.value);
          }}
          defaultValue={debouncedSearchPostTab}
          onBlur={onBlur}
          className={showSearchPostPanel ? "sTabInputEx" : "sTabInput"}
        />
      </div>
    </div>
  );
}

export default SearchPost;
