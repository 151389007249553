import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import "./QueuedTabs.scss";
import axios from "../../utils/Axios";
import Divider from "../Divider/Divider";
import { Context } from "../../utils/Context";
import TabsHeadCard from "../TabsHeadCard/TabsHeadCard";

function QueuedTabs() {
  const { t } = useTranslation(["components, extras"]);
  const { enqueueSnackbar } = useSnackbar();

  const { state, dispatch } = useContext(Context);
  const { userTabs, tabsHeadCard } = state;
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const acceptFunc = async (movingTabl) => {
    try {
      const { data } = await axios.get(`/accept?tab=${movingTabl}`, config);
      enqueueSnackbar(data.msg, {
        variant: data.status === true ? "success" : "error",
      });
      dispatch({ type: "TAB_ACCEPT" });
    } catch (error) {
      console.log(error);
    }
  };

  const ignoreFunc = async (ignTabl) => {
    try {
      const { data } = await axios.get(`/ignore?tab=${ignTabl}`, config);
      enqueueSnackbar(data.msg, {
        variant: data.status === true ? "success" : "error",
      });
      dispatch({ type: "TAB_ACCEPT" });
    } catch (error) {
      console.log(error);
    }
  };

  //Show Tab Page
  const showTabPage = (queue) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: queue[0] });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: queue[1] });
  };

  return (
    <>
      {userTabs?.queued_tabs?.length !== 0 && (
        <div className="qtContainer">
          <TabsHeadCard
            name={t("components:queueTabs")}
            tag="queued"
            click={() =>
              dispatch({ type: "TABS_HEAD_CARD", payload: "queued" })
            }
            icon={<img src="/assets/images/controlSettings.svg" alt="" />}
          />
          {userTabs?.queued_tabs?.map((queue, index) => {
            return (
              <div
                className={
                  tabsHeadCard?.includes("queued")
                    ? "quTabsHead"
                    : "quTabsHead quTabsHeadCol"
                }
              >
                <button
                  className="qtTab"
                  onClick={() => {
                    showTabPage(queue);
                  }}
                >
                  {`@${queue[0]}`}
                </button>
                <div className="qtBtn">
                  <button onClick={() => acceptFunc(queue[0])}>
                    {t("extras:btnAccept")}
                  </button>
                  <button onClick={() => ignoreFunc(queue[0])}>
                    {t("extras:btnReject")}
                  </button>
                </div>
              </div>
            );
          })}
          <div className="qtDivider">
            <Divider />
          </div>
        </div>
      )}
    </>
  );
}

export default QueuedTabs;
