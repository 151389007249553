import React, { useContext, useRef } from "react";
import "./Share.scss";
import { baseUrl } from "../../utils/Base";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

import { useSnackbar } from "notistack";
import { Context } from "../../utils/Context";
import { useClickOut } from "../../utils/Effects";

const Share = ({ postId, tabId, location, outType, onState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useContext(Context);
  const { currentTabId, forumToShare } = state;
  const iconBoxRef = useRef(null);
  const iconShareModalRef = useRef(null);

  const resolveUrl = () => {
    switch (location) {
      case "post":
        return `${baseUrl}/posts/${postId}/${tabId}`;
      case "tab":
        return `${baseUrl}/tabs/${currentTabId}`;
      default:
        return forumToShare;
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(resolveUrl())
      .then(() => {
        enqueueSnackbar("Link copied to clipboard!", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Failed to copy the link. Please try again.", {
          variant: "error",
        });
      });
  };

  useClickOut({
    onState,
    mainRef: iconShareModalRef,
    subRef: iconBoxRef,
    dispatch: dispatch,
    dispatchType: outType,
  });

  return (
    <div className="share-page" ref={iconShareModalRef}>
      <p>
        Share this{" "}
        {location === "post"
          ? "post"
          : location === "tab"
          ? "Tab link"
          : "forum link"}
      </p>
      <div className="copy_content" style={{ cursor: "pointer" }}>
        <p>{resolveUrl()?.substring(0, 20) + "..."}</p>
        <img
          src="/assets/images/copy.png"
          alt=""
          onClick={() => handleCopy()}
        />
      </div>
      <div className="share_icons" ref={iconBoxRef}>
        <WhatsappShareButton url={resolveUrl()}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <FacebookShareButton url={resolveUrl()}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <EmailShareButton url={resolveUrl()}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <LinkedinShareButton url={resolveUrl()}>
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton url={resolveUrl()}>
          <XIcon size={32} round={true} />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default Share;
