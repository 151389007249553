import { HelmetProvider, Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./MinorPagesLayout.scss";
import Footer from "../Footer/Footer";

function MinorPagesLayout({ title, description, children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="mlContainer">
      <HelmetProvider>
        <Helmet>
          <title>{title ? `TruTab | ${title}` : "TruTab"}</title>
          {description && (
            <meta name="description" content={description}></meta>
          )}
        </Helmet>
      </HelmetProvider>

      <div className="mlHeader">
        <nav className="mlNav">
          <Link to="/home">
            <img
              src="assets/images/nameLogoIn.svg"
              alt=""
              style={{
                height: "32px",
                paddingBottom: "3px",
              }}
              className="mlLogo"
              onClick={() => {
                navigate(splitLocation[1] === "public" ? "/login" : "/home");
              }}
            />
            <img
              src="/assets/images/logoMobile.svg"
              alt=""
              className="logoMobile"
              onClick={() => {
                navigate("/home");
              }}
            />
          </Link>
        </nav>
      </div>
      <main className="mlMain">{children}</main>
      <div className="mlFooter">
        <Footer />
      </div>
    </div>
  );
}

export default MinorPagesLayout;
