import { useContext, useRef, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import "./Search.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";

function Search() {
  const { t } = useTranslation(["components, extras"]);
  const inputRef = useRef(null);

  const { dispatch, state } = useContext(Context);
  const { searchWord, showSearchPanel } = state;
  const [debouncedSearchWord, setDebouncedSearchWord] = useState(searchWord);

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch({ type: "SET_SEARCH_WORD", payload: debouncedSearchWord }),
      1000
    );
    return () => clearTimeout(timer);
  }, [debouncedSearchWord]);

  useEffect(() => {
    const handleFilter = async () => {
      if (searchWord?.length >= 3) {
        dispatch({ type: "CLEAR_SEARCH_RESULT" });
        dispatch({ type: "START_SEARCH" });
        const { data } = await axios.get(`/u/search?q=${searchWord}`);
        dispatch({ type: "STOP_SEARCH" });
        dispatch({ type: "SET_SEARCH_RESULT", payload: data.result_srch });
      } else {
        dispatch({ type: "SET_SEARCH_WORD", payload: "" });
      }
    };
    handleFilter();
  }, [searchWord]);

  const clearSearchWord = () => {
    inputRef.current.value = "";
    dispatch({ type: "CLEAR_SEARCH_WORD" });
    dispatch({ type: "CLEAR_SEARCH_RESULT" });
    setDebouncedSearchWord("");
  };

  useEffect(() => {
    if (inputRef.current && showSearchPanel) {
      inputRef.current.focus();
    }
  }, [showSearchPanel]);

  return (
    <div>
      <div
        className={showSearchPanel ? "sInputContainerEx" : "sInputContainer"}
      >
        {searchWord?.length !== 0 && showSearchPanel ? (
          <CloseIcon className="sIcon" onClick={clearSearchWord} />
        ) : (
          <img
            src={`/assets/images/${
              showSearchPanel ? "searchBlue" : "searchGrey"
            }.svg`}
            alt=""
            className="sIcon"
            style={{ marginTop: `${!showSearchPanel && "1.5px"}` }}
          />
        )}
        <input
          type="text"
          placeholder={t("components:searchTextDefault")}
          onChange={(e) => {
            e.target.value.length < 1 && dispatch({ type: "STOP_SEARCH" });
            setDebouncedSearchWord(e.target.value);
          }}
          value={debouncedSearchWord}
          onBlur={() => dispatch({ type: "STOP_SEARCH" })}
          ref={inputRef}
          className={showSearchPanel ? "sInputEx" : "sInput"}
        />
      </div>
    </div>
  );
}

export default Search;
