import { useContext, useEffect, useRef, useState } from "react";
import "./TabSettings.scss";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Context } from "../../utils/Context";
import axios from "../../utils/Axios";
import { baseUrl } from "../../utils/Base";
import { useScrollToSection } from "../../utils/Effects";
import { useCopyTextToClipboard } from "../../utils/Copy";
import Divider from "../Divider/Divider";
import Share from "../Share/Share";

const CreateForum = ({ controlState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["components", "extras"]);
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const copyTextToClipboard = useCopyTextToClipboard();

  const [forumDesc, setForumDesc] = useState(null);
  const [forumLink, setForumLink] = useState(null);
  const [forumMsg, setForumMsg] = useState("");
  const [dur, setDur] = useState("999000");
  const forumDescRef = useRef();
  const createForumRef = useRef(null);

  const { state, dispatch } = useContext(Context);
  const { currentTabName, forumDetails, forumToShare } = state;

  const createForumLink = async (e) => {
    e.preventDefault();
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const formData = new FormData();
    formData.append("tab", currentTabName);
    formData.append("desc", forumDesc);
    formData.append("dur", parseInt(dur));

    try {
      setIsCreatingLink(true);
      setForumLink(null);
      const { data } = await axios.post(`/createforum`, formData, config);

      if (data?.forum_tkn) {
        setForumLink(`${baseUrl}/forums/${data?.forum_tkn}`);
        dispatch({ type: "SET_FORUM_ACTION" });
      } else {
        enqueueSnackbar(data?.msg, {
          variant: "error",
        });
      }

      setForumMsg(data?.msg);
      setForumDesc(null);
      forumDescRef.current.value = null;
      if (forumLink !== null) {
        await axios.get(`/trutab_qrcode?q=${forumLink}&f=png`);
      }
      setIsCreatingLink(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDurChange = (e) => {
    setDur(e.target.value);
  };

  useEffect(() => {
    if (!controlState) {
      setForumLink(null);
    }
  }, [controlState]);

  useScrollToSection(createForumRef, "start");

  return (
    <section className="createForum" ref={createForumRef}>
      <Divider />
      <div className="createForumTitle">{t("startForum")}</div>

      <div className="createForumBody">
        <div className="createForumBodyHead">{t("createLinkForum")}</div>
        <div className="createForumInstruction">{t("withForum")}</div>

        <form onSubmit={createForumLink}>
          <div className="tsFields">
            <label className="fmLabels">
              <span> {t("forumDesc")}</span> {t("visibleWhileForum")}
            </label>
            <div className="inviteInputContainer">
              <input
                type="text"
                ref={forumDescRef}
                className="tsInput"
                placeholder={t("DescribeThisPublicForum")}
                maxLength={64}
                required
                onChange={(e) => setForumDesc(e.target.value)}
              />
              <label className="inputBottomLabel">Max: 64 chars</label>
            </div>

            <section className="createForumDurContainer">
              <div className="createForumRadioContainer">
                <input
                  id="permanent"
                  type="radio"
                  value="999000"
                  checked={dur === "999000"}
                  onChange={handleDurChange}
                  className="createForumDurRadio"
                />
                <label htmlFor="permanent" className="createForumDurLabel">
                  <p className="createForumDurHead">{t("makeLinkPermanent")}</p>
                  <p className="createForumDurInstruction">
                    {t("canJoinInFuture")}
                  </p>
                </label>
              </div>

              <div className="createForumRadioContainer">
                <input
                  id="temporary"
                  type="radio"
                  value="24"
                  checked={dur === "24"}
                  onChange={handleDurChange}
                  className="createForumDurRadio"
                />
                <label htmlFor="temporary" className="createForumDurLabel">
                  <p className="createForumDurHead">{t("onlyForum")}</p>
                  <p className="createForumDurInstruction">
                    {t("cannotJoinFuture")}
                  </p>
                </label>
              </div>
            </section>
          </div>

          <div className="tsBtn">
            <button type="submit">
              {t("extras:createForum")}
              {isCreatingLink && (
                <CircularProgress size={15} className="btnProgress" />
              )}
            </button>
          </div>
          <div style={{ padding: "16px 0" }}>
            <Divider color="#ABABAB" />
          </div>

          {forumLink !== null && (
            <div style={{ position: "relative" }}>
              <small>{forumMsg}</small>
              <div className="tpFRShareContainer">
                {forumToShare !== null && (
                  <Share outType="HIDE_FORUM_TO_SHARE" onState={forumToShare} />
                )}
              </div>
              <div className="inviteLink">
                <span className="frLink">
                  {forumLink?.substring(0, 28)}...{" "}
                </span>
                <div className="cfShareCopy">
                  <div className="copyContainer">
                    <img
                      src="/assets/images/sharePurple.svg"
                      alt=""
                      onClick={() =>
                        dispatch({
                          type: "SET_FORUM_TO_SHARE",
                          payload: forumLink,
                        })
                      }
                    />
                  </div>
                  <div className="copyContainer">
                    <img
                      src="/assets/images/copyPurple.svg"
                      alt=""
                      onClick={() => copyTextToClipboard(forumLink)}
                    />
                  </div>
                </div>
              </div>
              <div className="qrCont">
                <img
                  src={`${baseUrl}/trutab_qrcode?q=${forumLink}&f=png`}
                  alt=""
                />
              </div>
              <span className="frLink">
                {`Entry Code (without url): ${forumDetails?.forum_code}`}
              </span>
              <div style={{ padding: "16px 0" }}>
                <Divider color="#ABABAB" />
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default CreateForum;
