import { useState, useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

import "./Home.scss";
import HomeLeftBox from "../../components/HomeLeftBox/HomeLeftBox";
import Footer from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";
import HomeProfile from "../../components/HomeProfile/HomeProfile";
import CreateTab from "../../components/CreateTab/CreateTab";
import Loading from "../../components/Loading/Loading";
import RightComponent from "../../components/RightComponent/RightComponent";
import {
  LoadingPage,
  CheckUserInfo,
  usePageTracking,
} from "../../utils/Effects";
import { Context } from "../../utils/Context";
import TabsYouControl from "../../components/TabsYouControl/TabsYouControl";
import QueuedTabs from "../../components/QueuedTabs/QueuedTabs";
import TabsYouPaticipate from "../../components/TabsYouPaticipate/TabsYouPaticipate";
import TabsYouFollow from "../../components/TabsYouFollow/TabsYouFollow";
import Divider from "../../components/Divider/Divider";
import { Favorite, FlagCircle, MailLock, Person2 } from "@mui/icons-material";
import Tips from "../../components/Tips/Tips";

export default function Home() {
  const { t } = useTranslation(["components", "extras"]);
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(Context);
  const { page, alertLeftColor } = state;

  useEffect(() => {
    dispatch({ type: "ERROR_PAGE_ON", payload: false });
  }, []);

  let isLoggedIn =
    localStorage.getItem("isLoggedIn") && localStorage.getItem("isLoggedIn");

  //Check if user is logged in
  CheckUserInfo();

  //Show loading page
  LoadingPage(setLoading);

  usePageTracking();

  return (
    <>
      {loading && isLoggedIn === null ? (
        <Loading />
      ) : (
        <Layout
          title={t("components:menuHome")}
          description="Trutab&aposs home page"
        >
          <div
            className={`left ${
              alertLeftColor ? " changeColorofLeftPanel" : ""
            }`}
          >
            {page === "home" ? (
              <>
                <div className="withPadding">
                  <HomeProfile text={t("components:profileDesc")} />
                  <CreateTab />
                  <div className="ycDivider">
                    <Divider />
                  </div>
                  <div className="qLinksHead">Quick Links</div>
                  <div className="qLinks">
                    <div className="linksGroup">
                      <Person2 sx={{ fontSize: "14px", color: "#572e91c5" }} />
                      <Link to={"/home/personal"}>
                        {t("extras:btnPersonal")}
                      </Link>
                    </div>
                    <div className="linksGroup">
                      <Favorite sx={{ fontSize: "14px", color: "#572e91c5" }} />
                      <Link to={"/home/liked"}> {t("extras:btnLiked")}</Link>
                    </div>
                    <div className="linksGroup">
                      {" "}
                      <MailLock
                        sx={{ fontSize: "14px", color: "#572e91c5" }}
                      />{" "}
                      <Link to={"/home/saved"}> {t("extras:btnSaved")}</Link>
                    </div>
                    <div className="linksGroup">
                      {" "}
                      <FlagCircle
                        sx={{ fontSize: "14px", color: "#572e91c5" }}
                      />
                      <Link to={"/home/flagged"}>
                        {" "}
                        {t("extras:btnFlagged")}
                      </Link>
                    </div>
                  </div>

                  <div className="ycDivider">
                    <Divider />
                  </div>
                  <div>
                    <HomeLeftBox />
                  </div>
                </div>
                <div className="footer">
                  <Footer />
                </div>
              </>
            ) : (
              <>
                <div className="withPadding">
                  <HomeProfile />
                  <Divider />
                  <TabsYouControl />
                  <QueuedTabs />
                  <TabsYouPaticipate />
                  <TabsYouFollow />
                </div>
                <div className="footer">
                  <Footer />
                </div>
              </>
            )}
          </div>
          <div className="middle">
            <Outlet />
          </div>

          <div className="right">
            <RightComponent />
          </div>
        </Layout>
      )}
      <Tips />
    </>
  );
}
