import "./LoginDivider.scss";
import { useTranslation } from "react-i18next";

function LoginDivider() {
  const { t } = useTranslation(["components"]);
  return (
    <div className="dContainer">
      <div className="dLine dLine2"></div>
      <span>{t("components:or")}</span>
      <div className="dLine dLine2"></div>
    </div>
  );
}

export default LoginDivider;
